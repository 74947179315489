// eslint-disable-next-line max-len
export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const namePattern = /^[A-Za-z-]*$/;
export const HOLIDAYS = [
  new Date('2024-01-01T00:00:00+07:00'),
  new Date('2024-02-09T00:00:00+07:00'),
  new Date('2024-02-10T00:00:00+07:00'),
  new Date('2024-02-11T00:00:00+07:00'),
  new Date('2024-02-12T00:00:00+07:00'),
  new Date('2024-02-13T00:00:00+07:00'),
  new Date('2024-02-14T00:00:00+07:00'),
  new Date('2024-02-15T00:00:00+07:00'),
  new Date('2024-04-18T00:00:00+07:00'),
  new Date('2024-04-30T00:00:00+07:00'),
  new Date('2024-05-01T00:00:00+07:00'),
  new Date('2024-09-02T00:00:00+07:00'),
  new Date('2024-09-03T00:00:00+07:00'),
  new Date('2025-01-01T00:00:00+07:00'),
  new Date('2025-01-25T00:00:00+07:00'),
  new Date('2025-01-26T00:00:00+07:00'),
  new Date('2025-01-27T00:00:00+07:00'),
  new Date('2025-01-28T00:00:00+07:00'),
  new Date('2025-01-29T00:00:00+07:00'),
  new Date('2025-01-30T00:00:00+07:00'),
  new Date('2025-01-31T00:00:00+07:00'),
  new Date('2025-02-01T00:00:00+07:00'),
  new Date('2025-04-05T00:00:00+07:00'),
  new Date('2025-04-06T00:00:00+07:00'),
  new Date('2025-04-07T00:00:00+07:00'),
  new Date('2025-04-07T00:00:00+07:00'),
  new Date('2025-04-30T00:00:00+07:00'),
  new Date('2025-05-01T00:00:00+07:00'),
  new Date('2025-05-02T00:00:00+07:00'),
  new Date('2025-05-03T00:00:00+07:00'),
  new Date('2025-05-04T00:00:00+07:00'),
  new Date('2025-08-30T00:00:00+07:00'),
  new Date('2025-08-31T00:00:00+07:00'),
  new Date('2025-09-01T00:00:00+07:00'),
  new Date('2025-09-02T00:00:00+07:00'),
]

export const HOLIDAYS_SET = new Set(HOLIDAYS.map(holiday => holiday.getTime()));
