import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
  class_price_percent,
  class_text_danger,
  format_date,
  format_float,
  format_price,
  format_value,
  getPreviousWeekdays,
  map_industry,
} from '../common/util';
import LoadingSpinner from '../components/LoadingSpinner';
import {format} from "date-fns";
import TDTrend from "../components/TDTrend";
import TDTickerVol from "../components/TDTickerVol";
import TDSignal from "../components/TDSignal";
import TDPrice from "../components/TDPrice";
import ModalService from "../services/ModalService";
import ModalSimple from "../components/ModalSimple";
import CTTService from "../services/CTTService";
import TDGDNN from "../components/TDGDNN";


function TableHeadIndex() {
  return (
    <thead>
      <tr>
        <th>date</th>
        <th>priceFlat</th>
        <th>pricePercent</th>
        <th>trend</th>
        <th>fs_rating</th>
        <th>gdnn</th>
      </tr>
    </thead>
  )
}

export function TableHeadBuy() {
  return (
    <thead>
      <tr>
        <th className="th-ticker-vol">ticker</th>
        <th className="th-signal">signal</th>
        <th className="th-price">price</th>
        <th>value</th>
        <th>gdnn</th>
      </tr>
    </thead>
  )
}

export function TableHeadBase() {
  return (
    <thead>
      <tr>
        <th style={{width:"50%"}}>ticker</th>
        <th style={{width:"30%"}}>signal</th>
        <th style={{width:"20%"}}>price</th>
      </tr>
    </thead>
  )
}

export function TableHeadSectorRank() {
  return (
    <thead>
    <tr>
      <th>#</th>
      <th style={{minWidth: 200}}>industry</th>
      <th>fs_rating</th>
      <th style={{minWidth: 300}}>tickers</th>
    </tr>
    </thead>
  )
}

function TableBody0({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <td className="text-end" onClick={() => ModalService.open(ModalSimple, {ticker})}>{ format_date(ticker.date) }</td>
          <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_price(ticker.priceFlat) }</td>
          <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{ format_float(ticker.pricePercent * 100) }%</td>
          <TDTrend ticker={ticker} />
          <td className={"text-end" + class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</td>
          <td className={"text-end" + class_text_danger(ticker.giaodichnn)}>{ ticker.giaodichnn }</td>
        </tr>
      ))
    }
    </tbody>
  )
}

export function TableBodyBuy({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker} highlight_own={false}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien} priceFlat={ticker.priceFlat}/>
          <TDPrice ticker={ticker}/>
          <td className="text-end">
            {format_value(ticker)}
          </td>
          <TDGDNN ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

export function TableBodyBase({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien} priceFlat={ticker.priceFlat}/>
          <TDPrice ticker={ticker}/>
          <td className="text-end">
            {format_value(ticker)}
          </td>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyWait({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.wait_signal} price={ticker.wait_price} priceFlat={ticker.priceFlat}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyBasis({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien} priceFlat={ticker.priceFlat}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyCashFlow({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien} priceFlat={ticker.priceFlat}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyVolume({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien} priceFlat={ticker.priceFlat}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}


function TableBodyForeign({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={'NN: ' + ticker.giaodichnn}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyBreak({ rows }) {
  return (
    <tbody>
    {
      rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien} priceFlat={ticker.priceFlat}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyPrice({ rows }) {
  return (
    <tbody>
    {
      rows&& rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.signal} price={ format_float(ticker.pricePercent * 100) + ' %'}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodySector({ rows }) {
  return (
    <tbody>
    {
      rows && rows.map((ticker, i) => (
        <tr key={ i }>
          <td style={{minWidth: 100}}>{ map_industry(ticker.ticker) }</td>
          <TDSignal signal={ticker.signal} price={'NN: ' + ticker.giaodichnn}/>
          <TDPrice ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodySectorRank({ rows }) {
  return (
    <tbody>
    {
      rows && rows.map((industry, i) => (
        <tr key={ i }>
          <td>{industry.index + 1}</td>
          <td>{industry.industry}</td>
          <td>{industry.value}</td>
          <td>
            <ol>
            {industry.tickers.map((ticker, j) => (
              <li key={j}>
                {ticker.ticker} | {ticker.priceFlat} ({format_float(ticker.pricePercent * 100)} %) | {ticker.fs_rating}
              </li>
            ))}
            </ol>
          </td>
        </tr>
      ))
    }
    </tbody>
  )
}

function TableBodyPullback({ rows }) {
  return (
    <tbody>
    {
      rows && rows.map((ticker, i) => (
        <tr key={ i }>
          <TDTickerVol ticker={ticker}/>
          <TDSignal signal={ticker.pullback} price={ticker.pullback_price} priceFlat={ticker.priceFlat}/>
          <TDPrice ticker={ticker}/>
          <td className="text-end">
            {format_value(ticker)}
          </td>
          <TDGDNN ticker={ticker}/>
        </tr>
      ))
    }
    </tbody>
  )
}

function Summary() {
  const title = 'Summary';
  const tabs = [
    { target: 'trend' },
    { target: 'buy', active: 'active' },
    { target: 'sell' },
    { target: 'wait_buy' },
    { target: 'wait_sell' },
    { target: 'basis' },
    { target: 'cash_flow' },
    { target: 'volume' },
    { target: 'foreign' },
    { target: 'break' },
    { target: 'price' },
    { target: 'sector' },
    { target: 'sector_rank' },
  ];
  const [isLoading, setLoad] = useState(true);
  const [dayOption, setDayOption] = useState(0);
  const [marginOption, setMarginOption] = useState('margin');
  const [data, setData] = useState({});
  const [rowIndustryRanking, setRowIndustryRanking] = useState([]);
  const [daySelect, setDaySelect] = useState([]);

  useEffect(() => {
    CTTService.fetch_summary(dayOption, marginOption).then(response => {
      setData(response)
      setLoad(false)
    });
    CTTService.get_industry_ranking().then(response => {
      setRowIndustryRanking(response.fsRating)
    });
    const daySelect = getPreviousWeekdays(new Date(), 6)
    setDaySelect(daySelect.map(item => {
      return format(item, 'EEEEEE dd/MM').toUpperCase();
    }))
  }, [dayOption, marginOption]);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  function changeDate(e) {
    setDayOption(e.target.value)
  }

  function changeMargin(e) {
    setMarginOption(e.target.value)
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col">
            <select className="form-select form-select-sm" aria-label=".form-select-sm" onChange={(e) => changeDate(e)}>
              {
                daySelect.map((item, i) => (
                  <option key={i} value={i}>{item}</option>
                ))
              }
            </select>
          </div>

          <div className="col">
            <select className="form-select form-select-sm" aria-label=".form-select-sm" onChange={(e) => changeMargin(e)} defaultValue={"margin"}>
              <option value="all">All</option>
              <option value="normal">Normal</option>
              <option value="margin">Margin</option>
            </select>
          </div>
        </div>

        <ul className="nav nav-pills" id="myTab" role="tablist">
          {
            tabs.map((item, i) => (
              <li key={i} className="nav-item" role="presentation">
                <button className={`nav-link ${item.active}`} id={`${item.target}-tab`} data-bs-toggle="tab" data-bs-target={`#${item.target}`} type="button" role="tab" aria-controls={item.target} aria-selected="true">{item.target}</button>
              </li>
            ))
          }
        </ul>

        <div className="tab-content">
          <div className="tab-pane fade" id="trend" role="tabpanel" aria-labelledby="trend-tab">
            <button className="btn btn-secondary" onClick={() => ModalService.open(ModalSimple, {ticker: {ticker: 'VN30'}})}>VN30</button>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top caption-top">
                <TableHeadIndex />
                <TableBody0 rows={data.marketOneData.VNINDEX} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade show active" id="buy" role="tabpanel" aria-labelledby="buy-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top caption-top">
                <caption>BUY</caption>
                <TableHeadBuy />
                <TableBodyBuy rows={data.marketTwoData.buy} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>PULLBACK</caption>
                <TableHeadBuy />
                <TableBodyPullback rows={data.marketTwoData.pullback} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="sell" role="tabpanel" aria-labelledby="sell-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyBase rows={data.marketTwoData.sell} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="wait_buy" role="tabpanel" aria-labelledby="wait_buy-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyWait rows={data.marketTwoData.wait_buy} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="wait_sell" role="tabpanel" aria-labelledby="wait_sell-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyWait rows={data.marketTwoData.wait_sell} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="basis" role="tabpanel" aria-labelledby="basis-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyBasis rows={data.marketTwoData.basis} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="cash_flow" role="tabpanel" aria-labelledby="cash_flow-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyCashFlow rows={data.marketTwoData.cash_flow} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="volume" role="tabpanel" aria-labelledby="volume-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHeadBase />
                <TableBodyVolume rows={data.marketTwoData.volume} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="foreign" role="tabpanel" aria-labelledby="foreign-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>BUY</caption>
                <TableHeadBase />
                <TableBodyForeign rows={data.marketTwoData.foreign_buy} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>SELL</caption>
                <TableHeadBase />
                <TableBodyForeign rows={data.marketTwoData.foreign_sell} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="break" role="tabpanel" aria-labelledby="break-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>UP</caption>
                <TableHeadBase />
                <TableBodyBreak rows={data.marketTwoData.break_up} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>DOWN</caption>
                <TableHeadBase />
                <TableBodyBreak rows={data.marketTwoData.break_down} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="price" role="tabpanel" aria-labelledby="price-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>UP</caption>
                <TableHeadBase />
                <TableBodyPrice rows={data.marketTwoData.price_up} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>DOWN</caption>
                <TableHeadBase />
                <TableBodyPrice rows={data.marketTwoData.price_down} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="sector" role="tabpanel" aria-labelledby="sector-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>INCREASE</caption>
                <TableHeadBase />
                <TableBodySector rows={data.marketTwoData.sector_increase} />
              </table>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>DECREASE</caption>
                <TableHeadBase />
                <TableBodySector rows={data.marketTwoData.sector_decrease} />
              </table>
            </div>
          </div>

          <div className="tab-pane fade" id="sector_rank" role="tabpanel" aria-labelledby="sector_rank-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <caption>FS RATING</caption>
                <TableHeadSectorRank />
                <TableBodySectorRank rows={rowIndustryRanking}/>
              </table>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Summary;
